<template>
  <div>
    <Card>
      <template #header>
        <div class="d-flex flex-column w-100">
          <h4 class="my-6">Mahalle - Köy</h4>
          <div class="row">
            <div class="col-md-4 col-12">
              <MultiSelect
                v-model="county"
                :options="counties"
                :multiple="false"
                label="İl Seçiniz"
                placeholder="İl Seçiniz"
                id="il"
              />
            </div>

            <div class="col-md-4 col-12">
              <MultiSelect
                v-model="district"
                :options="districts"
                :multiple="false"
                label="İlçe Seçiniz"
                placeholder="İlçe Seçiniz"
                id="ilce"
              />
            </div>
            <div class="col-md-4 col-12 mt-7">
              <Input placeholder="Ara.." v-model="search" class="mr-2" />
            </div>
          </div>
        </div>
      </template>

      <List
        v-if="districts"
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Mahalle - Köy Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
      </List>
      <p v-else>Lütfen il ve ilçe seçiniz.</p>
    </Card>
  </div>
</template>
<script>
import {
  DISTRICTS,
  NEIGHBOURHOODS,
} from "@/core/services/store/counties.module.js";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      search: "",
      filters: "",
      loading: false,
      header: [
        { text: "İl", value: "il_adi" },
        { text: "İlçe", value: "ilce_adi" },
        { text: "Mahalle", value: "mahalle_adi" },
      ],
      items: [],
      pagination: {},
      meta: {},
      districts: [],
      counties: [],
      street: [],
      county: {},
      district: {},
    };
  },

  created() {
    this.counties = this.setCountiesSelectValues(this.getCounties);
  },
  methods: {
    getList() {
      this.loading = true;

      this.totalItems = 3;
      this.pagination = {
        current_page: 1,
        total_pages: 9,
        total_items: 3,
      };
  


      let payload = { il_id: this.county.value, ilce_id: this.district.value };
      this.$store.dispatch(NEIGHBOURHOODS, payload).then((response) => {
        this.items = response;
        if (this.search) {
        this.items = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLocaleLowerCase('tr').includes(this.search)) {
              return true;
            }
          }
          return false;
        });
      } 
      });
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;
      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }
      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;
      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    getSelectedDistrict() {
      this.$store.dispatch(DISTRICTS, this.county.value).then((response) => {
        this.districts = this.setDistrictsSelectValues(response);
      });
    },

    setCountiesSelectValues(county) {
      const newCounties = county.map((index, key) => {
        return { text: index.il_adi, value: index.il_id };
      });
      return newCounties;
    },
    setDistrictsSelectValues(districts) {
      const setDistrictsSelect = districts.map((index, key) => {
        return { text: index.ilce_adi, value: index.ilce_id };
      });
      return setDistrictsSelect;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    county() {
      this.items = [];
      this.getSelectedDistrict();
    },
    district() {
      if (this.district?.value) {
        this.getList();
      }
    },
  },
  computed: {
    ...mapGetters(["getCounties"]),
  },
};
</script>
